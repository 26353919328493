import { render, staticRenderFns } from "./CheckboxRadio.vue?vue&type=template&id=4931a206&scoped=true&lang=pug&"
import script from "./CheckboxRadio.vue?vue&type=script&lang=js&"
export * from "./CheckboxRadio.vue?vue&type=script&lang=js&"
import style0 from "./CheckboxRadio.styl?vue&type=style&index=0&id=4931a206&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4931a206",
  null
  
)

export default component.exports